<template>
  <section class="section" :style="{
    backgroundColor: bgTransparent ? 'transparent' : 'white'
  }">
  <h1 v-if="title" class="section-title">{{ title }}</h1>
  <div v-if="card === 'location'" class="row mobile-scrolling">
  </div>
  <div v-else-if="card === 'destination'" class="row mobile-scrolling">
  </div>
  <div v-else-if="card === 'event'" class="row mobile-scrolling">
  </div>
  <div v-else-if="card === 'category'" class="row mobile-scrolling">
  </div>
  <div v-else-if="card === 'merkliste'" class="row mobile-scrolling">
    <merkliste-card v-for="(i, index) in data" :key="index" :item="i"></merkliste-card>
  </div>
</section>
</template>

<script>
  export default {
    name: 'CardSlider',
    components: {
      MerklisteCard: () => import('@/components/cards/Merkliste'),
    },
    props: {
      data: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      desktopAuto: {
        type: Boolean,
        default: false
      },
      bgTransparent: {
        type: Boolean,
        default: false
      },
      card: {
        type: String,
        required: true,
        default: 'basic',
        validator: card => ['event', 'category', 'merkliste','destination','location'].indexOf(card) !== -1
      },
      icon: {
        type: String,
        default: 'map',
        validator: icon => ['map', 'edit'].indexOf(icon) !== -1
      },
      isDeleteable: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      cardClicked(value){
        this.$emit('cardClicked',value);
      }
    }
  }
</script>
